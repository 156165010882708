'use client';

import { focusOnArticle } from '@/utils/focus';

const FocusToMainButton = () => {
	return (
		<button
			className="sr-only bg-primary-50 font-bold text-black underline focus:not-sr-only focus:absolute focus:z-200 focus:px-4"
			onClick={focusOnArticle}
		>
			Direct naar artikel
		</button>
	);
};

export default FocusToMainButton;
